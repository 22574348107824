// ================================================================
// PLUGINS ========================================================
// ================================================================



// ================================================================
// SITE ===========================================================
// ================================================================

$(function(){

	$(document).on('click', '.js-expand-block-2', function(e){
		e.preventDefault();
		$('.expanded-block-2').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-2', function(e){
		e.stopPropagation();
		$('.expanded-block-2').removeClass('open');
	});

	$(document).on('click', '.js-expand-block-3', function(e){
		e.preventDefault();
		$('.expanded-block-3').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-3', function(e){
		e.stopPropagation();
		$('.expanded-block-3').removeClass('open');
	});

	$(document).on('click', '.js-expand-block-4', function(e){
		e.preventDefault();
		$('.expanded-block-4').toggleClass('open');
	});

	$(document).on('click', '.js-close-expanded-block-4', function(e){
		e.stopPropagation();
		$('.expanded-block-4').removeClass('open');
	});

	$(document).on('click', '.js-mobile-header-trigger', function(e){
		e.preventDefault();
		$('.mobile-navigation').slideToggle();
	});

	$(document).on('click', '.js-open-mobile-sub', function(e){
		e.preventDefault();
		$(this).next('.mobile-navigation-sub').slideToggle();
	});

});


$(function() {
	// Newsletter Popup
    if (window.location.hash.indexOf('#popups-') > -1) {
        var popupUrl = window.location.hash.replace('#popups-', '');
        openPopup(popupUrl);
    }
    
    // attach magnific popup
    $(document).on('click', '.js-popup', function(e){
        e.preventDefault();

        $this = $(this);
        openPopup($this.attr('href'));
    });

    $(document).on('submit', '.js-on-submit-open-popup', function(e){
        e.preventDefault();
        
        var $this = $(this),
            email = $this.find('.mailing-input').val();

        $.magnificPopup.open({
            items : {src : $this.attr('action')},
            type : "ajax",
            removalDelay: 250,
            closeOnBgClick : false,
            mainClass: 'mfp-zoom-out',
            callbacks: {
                open: function() {
                    $('body').addClass('popup-open mod-blur');
                },
                close: function() {
                    $('body').removeClass('popup-open mod-blur');
                },
                ajaxContentAdded: function() {
                    $('.popup .email-input').val(email);
                },
            }
        });
    });

    $(document).on('click', '.js-popup-close', function(e){
        e.preventDefault();
        $.magnificPopup.instance.close();
    });

});

$('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
        || location.hostname == this.hostname) {

        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
           if (target.length) {
             $('html,body').animate({
                 scrollTop: target.offset().top
            }, 1000);
            return false;
        }
    }
});


function scrollFunction() {
    if ( $("#scrollTop").length ) {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
            $("#scrollTop").addClass('show-scroll-top');
            $("#scrollTop").addClass('scroll-top-transition');
            $("#scrollTop").removeClass('scroll-top');
        } else {
            $("#scrollTop").addClass('scroll-top');
            $("#scrollTop").removeClass('show-scroll-top');
        }
    }
}
// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    $('html, body').animate({
         scrollTop: 0
    }, 1000);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

$('.open-popup-link').magnificPopup({
  type:'inline',
  closeBtnInside: true,
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});


$(document).ready(function(){

    $('.homepage-carousel-slick').slick({
        draggable: false,
        arrows: false,
        dots: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    });

});